import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import {styleBrandSelector} from 'webpack/functions'; // eslint-disable-line import/no-unresolved
import style from 'style?useable'; // eslint-disable-line import/no-unresolved

// Sentry DSN is available in the application.ini
Sentry.init({
  dsn: 'https://5afbc9cd8fa446ca9576b6c4bbeffc4a@sentry.securewebsystems.net/34',
  whitelistUrls: [
    /js\/widget\.js/,
    /js\/widget-js\.js/,
    /js\/yr-widget\.js/,
    /\/widget\//,
  ],
});

/**
 * Reviews Widget Class
 */
class ReviewsWidget {

  /**
   * @param serverUrl
   * @param locale
   * @param bid
   * @param s
   * @param options
   *
   * @returns {Promise<void>}
   */
  static createWidget(serverUrl, locale, bid, s, options) {
    return (new ReviewsWidget(serverUrl, locale, bid, s, options))
      .create()
      .catch(e => Sentry.captureException(e));
  }

  /**
   *
   * @param serverUrl
   * @param locale
   * @param bid
   * @param s
   * @param options
   */
  constructor(serverUrl, locale, bid, s, options = {}) {
    this.serverUrl = serverUrl;
    this.locale = locale;
    this.bid = bid;
    this.script = s;
    this.options = options;
    this.cookieName = 'flexClosed';
    this.ldJsonRegExp = /<script type="application\/ld\+json">(.*)<\/script>/;
    this.flexWidgetRegExp = /<!--FLEX-->[\s\S]*<!--FLEX_END-->/;
  }

  /**
   * @param {{use: Function, unuse: Function}} style
   */
  static useStyle(useable) {
    if (ReviewsWidget.isStyleInjected()) {
      return;
    }
    useable.use();
  }

  static isStyleInjected() {
    return !!document.querySelector(styleBrandSelector(BRAND)); // eslint-disable-line no-undef
  }

  static isFlexInjected() {
    return !!document.querySelector('[data-id="widget-flex"]');
  }

  /**
   *
   * @param {string} ldJson
   * @returns {Node|undefined}
   */
  static addLdJsonToHead(ldJson) {
    // add json+ld to the head
    if (document.querySelector('script[data-id="widget-ldjson"]')) {
      return undefined;
    }
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('type', 'application/ld+json');
    scriptTag.setAttribute('data-id', 'widget-ldjson');
    scriptTag.appendChild(document.createTextNode(ldJson));
    return document.getElementsByTagName('head')[0].appendChild(scriptTag);
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async create() {
    ReviewsWidget.useStyle(style);
    return this.getContent().then(({html, ldJson}) => {
      if (ldJson) {
        ReviewsWidget.addLdJsonToHead(ldJson);
      }

      let widgetHtml = html;

      if (ReviewsWidget.isFlexInjected()) {
        const {content} = ReviewsWidget.extractContent(html, this.flexWidgetRegExp);
        widgetHtml = content;
      }

      this.widget = this.createWidget(widgetHtml);

      // the WidgetStandard has its own scrollable
      if (this.getWidgetStandard()) {
        this.scrollable = new Scrollable(this.getWidgetStandard());
        this.scrollable.startScrolling();
      }
      this.popup = new Popup(this.widget.querySelector('.WidgetPopup'));
      this.initFlex();
    });
  }

  /**
   * The StandardWidget contains the scrollable element in the widget, not inside the popup
   */
  getWidgetStandard() {
    return this.widget.querySelector('.WidgetStandard #auto_scroll');
  }

  /**
   *
   * @returns {Promise.<void>}
   */
  async getContent() {
    return fetch(this.getWidgetUrl())
      .then(async response => response.text())
      .then(this.extractLdJson.bind(this));
  }

  /**
   *
   * @returns {string}
   */
  getWidgetUrl() {
    return `${this.serverUrl}${this.locale}/widget/js/${this.bid}?${this.buildQueryParams(this.options)}`;
  }

  /**
   *
   * @returns {HTMLElement}
   */
  createWidget(content) {
    const widgetId = (this.options.type || 'widget-js') + this.bid;
    const widget = document.createElement('div');
    widget.setAttribute('id', widgetId);
    widget.innerHTML = content;
    return this.script.parentNode.insertBefore(widget, this.script);
  }

  /**
   * Add event listeners to close flex widget
   */
  initFlex() {
    // hide the widget if
    if (Cookies.get(this.cookieName)) {
      document.querySelectorAll('.widgetFlex').forEach(
        (element) => { element.style.cssText = 'display: none'; }
      );
    }

    document.querySelectorAll('[data-reviewsWidget-flex-close]').forEach(
      element => element.addEventListener('click', () => {
        document.querySelectorAll('.widgetFlex').forEach(
          (widgetFlex) => { widgetFlex.style.display = 'none'; }
        );

        // Set a session cookie to keep the widget closed for this session
        Cookies.set(this.cookieName, 1);
      })
    );
  }

  /**
   * Serialize a Object into a list of parameters
   *
   * @param obj
   * @param prefix
   * @param exclude
   * @returns {string}
   */
  buildQueryParams(obj, prefix, exclude) {
    const getKey = prefix ? val => `${prefix}[${val}]` : val => val;

    return Object.entries(obj)
      .filter(([key]) => key !== exclude)
      .map(([key, value]) => {
        if (typeof value !== 'object') {
          return `${encodeURIComponent(getKey(key))}=${encodeURIComponent(value)}`;
        }
        return this.buildQueryParams(value, getKey(key));
      }).join('&');
  }

  static extractContent(content = '', regexp = new RegExp()) {
    const match = content.match(regexp);
    return (match === null) ? {content, match} : {content: content.replace(match[0], ''), match};
  }

  /**
   *
   * @param {string} content
   * @returns {{html: string, ldJson: string|undefined}}
   */
  extractLdJson(content) {
    const {
      content: html,
      match: ldJson,
    } = ReviewsWidget.extractContent(content, this.ldJsonRegExp);

    // return if no json+ld found
    if (!ldJson) { return {html}; }

    return {
      ldJson: ldJson[1],
      html
    };
  }
}

class Popup {

  /**
   *
   * @param {HTMLElement} element
   */
  constructor(element){

    this.popup = element || document.createElement('div');
    this.scrollable = new Scrollable(this.popup.querySelector('#auto_scroll'));

    this.initEvents();
  }

  /**
   * Init behaviour events
   */
  initEvents() {

    /* Close popup */

    // close the popup by clicking it (needed for mobile devices where popup takes the whole screen)
    this.popup.addEventListener('click', () => {
      this.close();
      this.scrollable.stopScrolling();
    });

    // close the popup by clicking outside of the widget(incl. popup) (needed for desktop)
    document.addEventListener('click', (event) => {
      if(this.isOpen()) {
        this.close();
        this.scrollable.stopScrolling();
      }
    });

    // close the popup with "Escape" key
    document.addEventListener('keydown', (event) => {
      if(event.key === 'Escape' && this.isOpen()) {
        this.close();
        this.scrollable.stopScrolling();
      }
    });

    document.querySelectorAll('[data-reviewsWidget-popup-close]').forEach(element => {
      // close the popup clicking the (X) button
      element.addEventListener('click', (event) =>{
        event.stopPropagation();
        this.close();
        this.scrollable.stopScrolling();
      });

      // close the popup with the Enter key while the (X) button is focused (via the Tab key)
      element.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          this.close();
          this.scrollable.stopScrolling();
        }
      });
    });


    /* Open popup */

    document.querySelectorAll('[data-reviewsWidget-popup-open]').forEach(element => {

      // Open the popup with mouse click
      element.addEventListener('click', (event) =>{
        event.stopPropagation();
        this.open();
        this.scrollable.startScrolling();
      });

      // Toggle the popup with the Enter key while the element is focused (via the Tab key)
      element.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          if (this.isClosed()) {
            this.open();
            this.scrollable.startScrolling();
          } else {
            this.close();
            this.scrollable.stopScrolling();
          }
        }
      })
    });
  }

  /**
   *
   * @return {boolean}
   */
  isOpen() {
    return !this.isClosed();
  }

  /**
   *
   * @return {boolean}
   */
  isClosed() {
    return this.popup.classList.contains('closed')
  }

  /**
   *
   * @return {Popup}
   */
  open() {
    this.popup.classList.remove('closed');
    return this;
  }

  /**
   *
   * @return {Popup}
   */
  close() {
    this.popup.classList.add('closed');
    return this;
  }
}


class Scrollable {

  /**
   *
   * @param {HTMLElement} element
   * @param {function} scrollEnabled
   */
  constructor(element, scrollEnabled) {
    this.scrollDirection = 'up';
    this.scrollable  = element || document.createElement('div');

    this.initEvents(scrollEnabled);
  }

  initEvents(scrollEnabled = () => true) {
    this.scrollable.addEventListener('mouseenter', () => this.stopScrolling());
    this.scrollable.addEventListener('mouseleave', () => scrollEnabled() && this.startScrolling());
  }

  /**
   * Scroll the reviews
   */
  scroll() {
    if (this.scrollDirection === 'up') {
      if (this.scrollable.scrollTop >= this.scrollable.scrollHeight - this.scrollable.offsetHeight) {
        this.scrollDirection = 'down';
      }
      this.scrollable.scrollTop++;
    } else {
      if (this.scrollable.scrollTop <= 0) {
        this.scrollDirection = 'up';
      }
      this.scrollable.scrollTop--;
    }
  }

  /**
   * Start reviews scrolling
   */
  startScrolling(){
    // prevent creating multiple intervals
    if (!this.widgetScrollInterval) {
      this.widgetScrollInterval = setInterval(this.scroll.bind(this), 60);
    }
  }

  /**
   * Stop reviews scrolling
   */
  stopScrolling(){
    clearInterval(this.widgetScrollInterval);
    // to know that the interval is cleared
    delete this.widgetScrollInterval;
  }
}

window.getWidget = ReviewsWidget.createWidget;
window.getYRWidget = ReviewsWidget.createWidget;
